import { Button } from "../../land_ui/button/button";
import { Popover } from "../../land_ui/popover/popover";
import { Tooltip } from "../../land_ui/tooltip/tooltip";
import { Typography } from "../../land_ui/typography/typography";
import clsx from "clsx";
import { Icon } from "../../land_ui/icon/icon";
import { useLocalStorage } from "usehooks-ts";
import { StreetIcon } from "../../land_ui/icon/icons/Street";
import { SatelliteIcon } from "../../land_ui/icon/icons/Satellite";
import { Divider } from "../../land_ui/Divider/Divider";

export const LayerOptions = {
    satellite: "mapbox://styles/mapbox/satellite-v9",
    street: "mapbox://styles/mapbox/outdoors-v12",
} as const;

interface MapLayerControlProps {
    setLayer: (layer: string) => void;
}

export function useMapLayer() {
    const [mapLayer, setMapLayer] = useLocalStorage<keyof typeof LayerOptions>(
        "landinsights-map-layer-setting",
        "satellite", // default value
    );

    return {
        mapLayer,
        setMapLayer,
        mapLayerOption: LayerOptions[mapLayer],
    };
}

export function MapLayerControl({ setLayer }: MapLayerControlProps) {
    const { mapLayer, setMapLayer } = useMapLayer();
    const mapLayerOptions = [
        {
            label: "Satellite",
            name: "satellite",
            icon: SatelliteIcon,
        },
        {
            label: "Street",
            name: "street",
            icon: StreetIcon,
        },
    ] as const;
    return (
        <Popover
            trigger={
                <div>
                    <Tooltip
                        trigger={<Button variant="secondary" icon="Globe" />}
                        content="Map Layers"
                        placement="left"
                    />
                </div>
            }
            placement="left"
        >
            <div className="lui-flex lui-flex-col lui-py-2  lui-overflow-hidden lui-bg-white lui-rounded-[24px] lui-shadow-lg lui-min-w-[260px]">
                <Typography weight="bold" className="lui-py-2 lui-px-4">
                    Map Layers
                </Typography>

                <Divider horizontal />

                <div className="lui-flex lui-flex-col ">
                    {mapLayerOptions.map((layer) => (
                        <div
                            className={clsx("lui-px-4  ", {
                                "lui-bg-primary-50 hover:lui-bg-primary-50":
                                    layer.name == mapLayer,
                                "hover:lui-bg-gray-100": layer.name != mapLayer,
                            })}
                        >
                            <Button
                                variant="base"
                                onClick={() => {
                                    setLayer(LayerOptions[layer.name]);
                                    setMapLayer(layer.name);
                                }}
                                fullWidth
                            >
                                <span
                                    className={clsx(
                                        "lui-flex lui-justify-between lui-w-full lui-gap-2.5 lui-items-center",
                                    )}
                                >
                                    <span
                                        className={clsx(
                                            "lui-flex lui-gap-2.5 lui-items-center lui-no-underline lui-w-24",
                                            {
                                                "lui-text-primary-500":
                                                    layer.name == mapLayer,
                                            },
                                        )}
                                    >
                                        {layer.icon()}
                                        {layer.label}
                                    </span>
                                    <span>
                                        {mapLayer == layer.name && (
                                            <Icon
                                                name="Check"
                                                size="md"
                                                color="primary-500"
                                            />
                                        )}
                                    </span>
                                </span>
                            </Button>
                        </div>
                    ))}
                </div>
            </div>
        </Popover>
    );
}
