import clsx from "clsx";
import { createElement, forwardRef } from "react";
import { Typography } from "../typography/typography";
import { Icon, IconNameType } from "../icon/icon";

import "./button.css";

export interface ButtonProps {
    disabled?: boolean;
    loading?: boolean;
    content?: string;
    fullWidth?: boolean;
    variant?: "primary" | "secondary" | "inline" | "base";
    type?: "button" | "submit";
    onClick?: () => void;
    children?: React.ReactNode;
    className?: string;
    icon?: IconNameType;

    // Link button
    href?: string;
    openInNewTab?: boolean;
}

/**
 * Button component
 * Use base or inline when you don't need lot of styling, base is the simplified version of inline
 * Use href prop to make it a link button
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            disabled,
            loading,
            variant = "primary",
            icon,
            fullWidth,
            type = "button",
            onClick,
            children,
            className,
            href,
            openInNewTab,
        },
        ref,
    ) => {
        const nestedChildren = (
            <>
                {icon && <Icon name={icon} color="inherit" />}

                {loading && "Loading..."}

                {children && (
                    <Typography
                        variant="span"
                        weight="bold"
                        color="inherit"
                        className={clsx("lui-w-full", { "lui-ml-2": icon })}
                    >
                        {children}
                    </Typography>
                )}
            </>
        );
        return createElement(
            href ? "a" : "button",
            {
                ref,
                type: !href ? type : null,
                className: clsx(
                    "lui-button",
                    `lui-button--${variant}`,
                    {
                        "lui-button--full-width": fullWidth,
                        "lui-button--icon": icon,
                        "lui-button--circle": icon && !children,
                    },
                    className,
                ),
                disabled: disabled || loading,
                onClick,
                target: openInNewTab ? "_blank" : null,
                href: href,
            },
            nestedChildren,
        );
    },
);
