import { Button } from "../../land_ui/button/button";
import { Divider } from "../../land_ui/Divider/Divider";
import { Icon } from "../../land_ui/icon/icon";
import { Popover } from "../../land_ui/popover/popover";
import { Tooltip } from "../../land_ui/tooltip/tooltip";
import { Typography } from "../../land_ui/typography/typography";

export function MapToolsControl() {
    return (
        <Popover
            trigger={
                <div>
                    <Tooltip
                        trigger={<Button variant="secondary" icon="Tool" />}
                        content="Tools"
                        placement="left"
                    />
                </div>
            }
            placement="left"
        >
            <div className="lui-flex lui-flex-col lui-py-2 lui-bg-white lui-rounded-[24px] lui-shadow-lg lui-min-w-[260px] lui-overflow-hidden">
                <Typography weight="bold" className="lui-py-2 lui-px-4">
                    Tools
                </Typography>
                <Divider horizontal />
                <div className="lui-flex lui-flex-col">
                    <div className="lui-flex lui-justify-between lui-items-center lui-px-4  hover:lui-bg-gray-100 ">
                        <Button variant="base" fullWidth>
                            <Typography
                                variant="span"
                                weight="medium"
                                className="lui-flex lui-gap-2"
                            >
                                <Icon name="Ruler" size="md" />
                                Ruler
                            </Typography>
                        </Button>
                    </div>
                </div>
            </div>
        </Popover>
    );
}
