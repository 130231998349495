import React, {
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import type { TypeaheadRef } from "react-bootstrap-typeahead";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Alert from "react-bootstrap/Alert";

import { SKIP_TRACE_REPORT_TYPE_PREMIUM } from "../../../constants";
import { fetch, pluralize, formatNumber } from "functions";

import type {
    AutocompleteResult,
    CountyOption,
    FIPSLookupResult,
    Option,
    ParcelExportFields,
    ParcelViewSetSearchResult,
    SavedList,
    SearchFilters,
    SearchResult,
} from "../types";

import { ParcelViewerContext } from "../context";
import SaveListModal from "../modals/save_list";
import { ScrollSpy, scrollToSection } from "../ui/scrollspy";
import { TitleBarButton } from "../ui/titlebar_button";
import { NavButton } from "../ui/nav_button";
import ExportListModal from "../modals/export_list";
import MapIconButton from "../ui/map_button";
import ReactMapControl from "../ui/map_control";
import { LIAsyncTypeahead, setAsyncTypeaheadValue } from "../ui/typeahead";

const SECTION_PARCEL = "parcel";
const SECTION_OWNERSHIP = "ownership";
const SECTION_STRUCTURE = "structure";
const SECTION_OTHER = "other";
const SECTION_SCRUBBING = "scrub";
const SECTION_SKIPPING = "skip";

interface FilterControlProps {
    open: boolean;
    setOpen: (value: boolean) => void;
}

export default function FilterControl({ open, setOpen }: FilterControlProps) {
    return (
        <ReactMapControl position="top-right">
            <MapIconButton
                icon="fa-solid fa-filter"
                title="Filter"
                onClick={() => setOpen(!open)}
            />
        </ReactMapControl>
    );
}

interface FilterPanelProps {
    setOpen: (value: boolean) => void;
    searchResult: SearchResult;
    setSearchResult: (result: SearchResult) => void;
}

export function FilterPanel({
    setOpen,
    searchResult,
    setSearchResult,
}: FilterPanelProps) {
    const containerRef = useRef();
    const { savedList } = useContext(ParcelViewerContext);
    const [prevSavedList, setPrevSavedList] = useState<SavedList>();

    // Form UI state
    const [activeSection, setActiveSection] = useState(SECTION_PARCEL);

    // Filter form fields
    const [countyOption, setCountyOption] = useState<CountyOption>(null);
    const [subdivision, setSubdivision] = useState("");
    const [includeZips, setIncludeZips] = useState("");
    const [excludeZips, setExcludeZips] = useState("");
    const [zoning, setZoning] = useState("");
    const [minAcreage, setMinAcreage] = useState("");
    const [maxAcreage, setMaxAcreage] = useState("");
    const [improvement, setImprovement] = useState("");
    const [outOfState, setOutOfState] = useState<boolean>(undefined);
    const [outOfCounty, setOutOfCounty] = useState<boolean>(undefined);
    const [outOfZip, setOutOfZip] = useState<boolean>(undefined);
    const [ownerOccupied, setOwnerOccupied] = useState("");
    const [ownershipLength, setOwnershipLength] = useState("");
    const [schoolDistrict, setSchoolDistrict] = useState("");
    const [buildingSqft, setBuildingSqft] = useState("");
    const [buildingsCount, setBuildingsCount] = useState("");
    const [yearBuilt, setYearBuilt] = useState("");

    // Parcel Export fields
    const [scrubDuplicates, setScrubDuplicates] = useState(true);
    const [scrubSizePreference, setScrubSizePreference] = useState("larger");

    // Land Scrubbing form fields
    const [scrubLandLocked, setScrubLandLocked] = useState(true);
    const [scrubWetlandsAllowed, setScrubWetlandsAllowed] = useState(0);
    const [scrubFloodZoneAllowed, setScrubFloodZoneAllowed] = useState(0);

    // Skip Tracing form fields
    const [skipTraceReportType, setSkipTraceReportType] = useState("");
    const [scrubDNC, setScrubDNC] = useState(false);

    // Form field refs
    const countyRef = useRef<TypeaheadRef>();
    const subdivisionRef = useRef<TypeaheadRef>();
    const zoningRef = useRef<TypeaheadRef>();
    const schoolDistrictRef = useRef<TypeaheadRef>();

    // Form state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    // Parcel API response
    const { count } = searchResult || {};

    // Modal UI state
    const [showExportListModal, setShowExportListModal] = useState(false);
    const [showSaveListModal, setShowSaveListModal] = useState(false);

    const searchParcel = useCallback(
        async (searchFilters: SearchFilters) => {
            setLoading(true);

            try {
                const params: Record<string, any> = {
                    page_size: 50000,
                    ...searchFilters,
                };
                const queryString = new URLSearchParams(params).toString();
                const searchResult: ParcelViewSetSearchResult = await fetch(
                    `/api/property/parcels/search?${queryString}`,
                );
                console.log("Filter result", searchResult);
                setSearchResult(searchResult);
            } catch (xhr) {
                console.log("Error fetching search results", xhr);
            }

            setLoading(false);
        },
        [setSearchResult],
    );

    const validate = (filters: SearchFilters) => {
        if (!filters.county) {
            setError("County field is required");
            return false;
        }
        return true;
    };

    const applyFilters = useCallback(
        (filters: SearchFilters) => {
            setError("");
            setSearchResult(null);
            if (validate(filters)) {
                searchParcel(filters);
            }
        },
        [setSearchResult, searchParcel],
    );

    const clearFilters = () => {
        setError("");
        setSearchResult(null);

        setCountyOption(null);
        setSubdivision("");
        setIncludeZips("");
        setExcludeZips("");
        setZoning("");
        setMinAcreage("");
        setMaxAcreage("");
        setImprovement("");
        setOutOfState(undefined);
        setOutOfCounty(undefined);
        setOutOfZip(undefined);
        setOwnerOccupied("");
        setOwnershipLength("");
        setBuildingSqft("");
        setBuildingsCount("");
        setYearBuilt("");
        setSchoolDistrict("");

        countyRef.current.clear();
        subdivisionRef.current.clear();
        zoningRef.current.clear();
        schoolDistrictRef.current.clear();
    };

    // Serialize search filters for Saved Lists
    const dumpSearchFilters = () => {
        return {
            version: 0,
            county: countyOption?.id || "",
            county_label: countyOption?.label || "",
            county_name: countyOption?.county || "",
            county_state: countyOption?.state || "",
            subdivision: subdivision || "",
            zips_include: includeZips,
            zips_exclude: excludeZips,
            zoning: zoning || "",
            acres_min: minAcreage || "",
            acres_max: maxAcreage || "",
            improvement_percentage_max: improvement,
            out_of_state_owner: outOfState,
            out_of_county_owner: outOfCounty,
            out_of_zip_owner: outOfZip,
            owner_occupied: ownerOccupied || "",
            ownership_length_min: ownershipLength,
            school_district: schoolDistrict || "",
            sq_ft_max: buildingSqft,
            structure_count_max: buildingsCount,
            year_built_max: yearBuilt,
        } as SearchFilters;
    };

    const dumpParcelExportFields = (): ParcelExportFields => {
        const filename = generateExportListFilename(countyOption);
        const searchFilters = dumpSearchFilters();
        return {
            // Parcel Export fields
            filename,
            search_filters: searchFilters,
            scrub_duplicates: scrubDuplicates,
            size_preference: scrubSizePreference,
            // Land Scrubbing fields
            land_locked: scrubLandLocked,
            wetlands_allowed: scrubWetlandsAllowed,
            flood_zone_allowed: scrubFloodZoneAllowed,
            // Skip Tracing fields
            report_type: skipTraceReportType,
            scrub_dnc: scrubDNC,
        };
    };

    // Deserialize search filters from Saved Lists
    const loadSearchFilters = useCallback(
        (filters: SearchFilters) => {
            setCountyOption({
                id: filters.county,
                label: filters.county_label,
                county: filters.county_name,
                state: filters.county_state,
            });
            setSubdivision(filters.subdivision || "");
            setIncludeZips(filters.zips_include || "");
            setExcludeZips(filters.zips_exclude || "");
            setZoning(filters.zoning || "");
            setMinAcreage(filters.acres_min || "");
            setMaxAcreage(filters.acres_max || "");
            setImprovement(filters.improvement_percentage_max || "");
            setOutOfState(toBool(filters.out_of_state_owner));
            setOutOfCounty(toBool(filters.out_of_county_owner));
            setOutOfZip(toBool(filters.out_of_zip_owner));
            setOwnerOccupied(filters.owner_occupied || "");
            setOwnershipLength(filters.ownership_length_min || "");
            setBuildingSqft(filters.sq_ft_max || "");
            setBuildingsCount(filters.structure_count_max || "");
            setYearBuilt(filters.year_built_max || "");
            setSchoolDistrict(filters.school_district || "");

            setAsyncTypeaheadValue(countyRef.current, filters.county_label || "");
            setAsyncTypeaheadValue(subdivisionRef.current, filters.subdivision || "");
            setAsyncTypeaheadValue(zoningRef.current, filters.zoning || "");
            setAsyncTypeaheadValue(
                schoolDistrictRef.current,
                filters.school_district || "",
            );

            applyFilters(filters);
        },
        [applyFilters],
    );

    // Handle loading Saved List
    useEffect(() => {
        if (savedList && savedList !== prevSavedList) {
            console.log("Loading Saved List", savedList);
            loadSearchFilters(savedList.search_filters);
            setPrevSavedList(savedList);
        }
    }, [savedList, loadSearchFilters, prevSavedList, setPrevSavedList]);

    return (
        <div id="filter-control" className="d-flex flex-column">
            {showExportListModal && (
                <ExportListModal
                    dumpParcelExportFields={dumpParcelExportFields}
                    onClose={() => setShowExportListModal(false)}
                />
            )}
            {showSaveListModal && (
                <SaveListModal
                    initialTitle={generateSaveListTitle(countyOption)}
                    dumpSearchFilters={dumpSearchFilters}
                    onClose={() => setShowSaveListModal(false)}
                />
            )}
            <div id="filter-control-main" className="d-flex flex-fill">
                <div id="filter-control-nav" className="d-flex flex-column">
                    <NavButton
                        name="Parcel"
                        icon="fa-map-marker"
                        selected={activeSection === SECTION_PARCEL}
                        onClick={() => scrollToSection(containerRef, SECTION_PARCEL)}
                    />
                    <NavButton
                        name="Ownership"
                        icon="fa-user-group"
                        selected={activeSection === SECTION_OWNERSHIP}
                        onClick={() => scrollToSection(containerRef, SECTION_OWNERSHIP)}
                    />
                    <NavButton
                        name="Structure"
                        icon="fa-house"
                        selected={activeSection === SECTION_STRUCTURE}
                        onClick={() => scrollToSection(containerRef, SECTION_STRUCTURE)}
                    />
                    <NavButton
                        name="Other"
                        icon="fa-plus"
                        selected={activeSection === SECTION_OTHER}
                        onClick={() => scrollToSection(containerRef, SECTION_OTHER)}
                    />
                    <NavButton
                        name="Scrubbing"
                        icon="fa-plus"
                        selected={activeSection === SECTION_SCRUBBING}
                        onClick={() => scrollToSection(containerRef, SECTION_SCRUBBING)}
                    />
                    <NavButton
                        name="Skip Tracing"
                        icon="fa-plus"
                        selected={activeSection === SECTION_SKIPPING}
                        onClick={() => scrollToSection(containerRef, SECTION_SKIPPING)}
                    />
                </div>
                <div
                    id="filter-control-content"
                    className="d-flex flex-column flex-fill"
                >
                    <div id="filter-control-header" className="d-flex py-3">
                        <h4 className="flex-fill mb-0">Filter Parcels</h4>
                        <TitleBarButton icon="fa-ellipsis" />
                        <TitleBarButton
                            icon="fa-circle-xmark"
                            onClick={() => setOpen(false)}
                        />
                    </div>
                    <ScrollSpy
                        containerRef={containerRef}
                        onActiveSectionChange={setActiveSection}
                    />
                    <div
                        ref={containerRef}
                        id="filter-control-body"
                        className="flex-fill pe-3 pb-12"
                    >
                        <SectionHeader section={SECTION_PARCEL} first>
                            Parcel
                        </SectionHeader>

                        {!!error && <Alert variant="danger">{error}</Alert>}

                        <div className="form-group">
                            <Label
                                htmlFor="countyInput"
                                tooltip="The county that the parcel is located in."
                            >
                                County
                            </Label>
                            <CountyFIPSSearch
                                inputRef={countyRef}
                                id="countyInput"
                                onChange={setCountyOption}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="subdivisionSelect"
                                tooltip="The subdivision that the parcel is located in."
                            >
                                Subdivision
                            </Label>
                            <Autocomplete
                                inputRef={subdivisionRef}
                                id="subdivisionSelect"
                                county={countyOption?.id}
                                field="SubdivisionName"
                                placeholder="Enter Subdivision..."
                                onChange={setSubdivision}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="includeZips"
                                tooltip="The ZIP codes you want to scope the search down to."
                            >
                                Include ZIPs
                            </Label>
                            <input
                                id="includeZips"
                                type="text"
                                className="form-control"
                                value={includeZips}
                                onChange={(e) => setIncludeZips(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="excludeZips"
                                tooltip="The ZIP codes you want to exclude from the search criteria."
                            >
                                Exclude ZIPs
                            </Label>
                            <input
                                id="excludeZips"
                                type="text"
                                className="form-control"
                                value={excludeZips}
                                onChange={(e) => setExcludeZips(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="zoning"
                                tooltip="The zoning category that county has deemed."
                            >
                                Zoning
                            </Label>
                            <Autocomplete
                                inputRef={zoningRef}
                                id="zoning"
                                county={countyOption?.id}
                                field="Zoning"
                                placeholder="Enter Zoning..."
                                onChange={setZoning}
                                minLength={2}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="minAcreage"
                                tooltip="The minimum and maximum of acres you want to include in your search. A blank field means you don't want to set a minimum or maximum."
                            >
                                Acreage
                            </Label>
                            <div className="input-group">
                                <input
                                    id="minAcreage"
                                    type="number"
                                    className="form-control"
                                    value={minAcreage}
                                    onChange={(e) => setMinAcreage(e.target.value)}
                                    placeholder="Min"
                                />
                                <input
                                    id="maxAcreage"
                                    type="number"
                                    className="form-control"
                                    value={maxAcreage}
                                    onChange={(e) => setMaxAcreage(e.target.value)}
                                    placeholder="Max"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="improvement"
                                tooltip="How much the overall value of the parcel has increased past just the land itself. Determined by the county accessor."
                            >
                                Improvement %
                            </Label>
                            <input
                                id="improvement"
                                type="number"
                                className="form-control"
                                value={improvement}
                                onChange={(e) => setImprovement(e.target.value)}
                                placeholder="%"
                            />
                        </div>

                        <SectionHeader section={SECTION_OWNERSHIP}>
                            Ownership
                        </SectionHeader>

                        <div className="form-group">
                            <Label
                                htmlFor="outOfState"
                                tooltip="Remove owners that live in the same state as the parcel."
                            >
                                Out Of State Owner
                            </Label>
                            <BooleanSelect
                                id="outOfState"
                                value={outOfState}
                                onChange={setOutOfState}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="outOfCounty"
                                tooltip="Remove owners that live in the same county as the parcel."
                            >
                                Out Of County Owner
                            </Label>
                            <BooleanSelect
                                id="outOfCounty"
                                value={outOfCounty}
                                onChange={setOutOfCounty}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="outOfZip"
                                tooltip="Remove owners that live in the same ZIP as the parcel."
                            >
                                Out Of ZIP Owner
                            </Label>
                            <BooleanSelect
                                id="outOfZip"
                                value={outOfZip}
                                onChange={setOutOfZip}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="ownerOccupied"
                                tooltip="Remove owners that occupy the parcel."
                            >
                                Owner Occupied
                            </Label>
                            <EnumSelect
                                id="ownerOccupied"
                                options={[
                                    { value: "", label: "" },
                                    { value: "N", label: "No" },
                                    { value: "Y", label: "Yes" },
                                ]}
                                value={ownerOccupied}
                                onChange={setOwnerOccupied}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="ownershipLength"
                                tooltip="Remove owners that haven't owned the parcel for at least a certain amount of months. Putting 1 translates to 1 month, putting 12 translates to 1 year, etc."
                            >
                                Minimum Ownership Length
                            </Label>
                            <input
                                id="ownershipLength"
                                type="number"
                                className="form-control"
                                value={ownershipLength}
                                onChange={(e) => setOwnershipLength(e.target.value)}
                                placeholder="Minimum Months Owned"
                            />
                        </div>

                        <SectionHeader section={SECTION_STRUCTURE}>
                            Structure
                        </SectionHeader>

                        <div className="form-group">
                            <Label
                                htmlFor="buildingSqft"
                                tooltip="The maximum total amount of square feet of structures. Set 0 for no structures."
                            >
                                Total Sqft Feet Of Structures
                            </Label>
                            <input
                                id="buildingSqft"
                                type="number"
                                className="form-control"
                                value={buildingSqft}
                                onChange={(e) => setBuildingSqft(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="buildingsCount"
                                tooltip="The maximum amount of buildings. Set 0 for structures."
                            >
                                Total Structure Count
                            </Label>
                            <input
                                id="buildingsCount"
                                type="number"
                                className="form-control"
                                value={buildingsCount}
                                onChange={(e) => setBuildingsCount(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="yearBuilt"
                                tooltip="The year the latest significant construction occurred on the building."
                            >
                                Structure Year Built
                            </Label>
                            <input
                                id="yearBuilt"
                                type="number"
                                className="form-control"
                                value={yearBuilt}
                                onChange={(e) => setYearBuilt(e.target.value)}
                            />
                        </div>

                        <SectionHeader section={SECTION_OTHER}>Other</SectionHeader>

                        <div className="form-group">
                            <Label
                                htmlFor="schoolDistrict"
                                tooltip="Remove parcels that aren't within the chosen school district(s)"
                            >
                                School District
                            </Label>
                            <Autocomplete
                                inputRef={schoolDistrictRef}
                                id="schoolDistrict"
                                county={countyOption?.id}
                                field="SchoolDistrictName"
                                placeholder="Enter School District Name..."
                                onChange={setSchoolDistrict}
                            />
                        </div>

                        <SectionHeader section={SECTION_SCRUBBING}>
                            Scrubbing
                        </SectionHeader>

                        <div className="form-group">
                            <Label htmlFor="scrubLandLocked">
                                Remove Land Locked Parcels
                            </Label>
                            <BooleanSelect
                                id="scrubLandLocked"
                                value={scrubLandLocked}
                                onChange={setScrubLandLocked}
                            />
                        </div>
                        <div className="form-group">
                            <Label htmlFor="scrubDuplicates">
                                Remove Duplicate Owners
                            </Label>
                            <BooleanSelect
                                id="scrubDuplicates"
                                value={scrubDuplicates}
                                onChange={setScrubDuplicates}
                            />
                        </div>
                        {scrubDuplicates && (
                            <div className="form-group">
                                <Label htmlFor="scrubSizePreference">
                                    Keep Which Parcel When Deduplicating
                                </Label>
                                <EnumSelect
                                    id="scrubSizePreference"
                                    options={[
                                        { value: "smaller", label: "Smaller" },
                                        { value: "larger", label: "Larger" },
                                    ]}
                                    value={scrubSizePreference}
                                    onChange={setScrubSizePreference}
                                />
                            </div>
                        )}
                        <div className="form-group">
                            <Label htmlFor="scrubWetlandsAllowed">
                                Maximum Wetland Cover
                            </Label>
                            <input
                                id="scrubWetlandsAllowed"
                                type="number"
                                className="form-control"
                                value={scrubWetlandsAllowed}
                                onChange={(e) =>
                                    setScrubWetlandsAllowed(
                                        parseInt(e.target.value, 10),
                                    )
                                }
                                placeholder="%"
                            />
                        </div>
                        <div className="form-group">
                            <Label htmlFor="scrubFloodZoneAllowed">
                                Maximum Flood Cover
                            </Label>
                            <input
                                id="scrubFloodZoneAllowed"
                                type="number"
                                className="form-control"
                                value={scrubFloodZoneAllowed}
                                onChange={(e) =>
                                    setScrubFloodZoneAllowed(
                                        parseInt(e.target.value, 10),
                                    )
                                }
                                placeholder="%"
                            />
                        </div>

                        <SectionHeader section={SECTION_SKIPPING}>
                            Skip Tracing
                        </SectionHeader>

                        <div className="form-group">
                            <Label htmlFor="skipTraceReportType">Skipping</Label>
                            <EnumSelect
                                id="skipTraceReportType"
                                options={[
                                    { value: "", label: "No Skipping" },
                                    {
                                        value: "standard",
                                        label: "Standard (1 credit / row)",
                                    },
                                    {
                                        value: "premium",
                                        label: "Premium (5 credit / row)",
                                    },
                                ]}
                                value={skipTraceReportType}
                                onChange={setSkipTraceReportType}
                            />
                        </div>
                        {skipTraceReportType === SKIP_TRACE_REPORT_TYPE_PREMIUM && (
                            <div className="form-group">
                                <Label htmlFor="scrubDNC">Scrub DNC?</Label>
                                <BooleanSelect
                                    id="scrubDNC"
                                    value={scrubDNC}
                                    onChange={setScrubDNC}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div id="filter-control-footer" className="border-top">
                <div
                    id="filter-control-status"
                    className="bg-gradient-primary text-center"
                >
                    <h5 className="my-3">
                        {loading
                            ? "Loading..."
                            : count > 0
                              ? `${formatNumber(count)} ${pluralize("Parcel", count)} Found`
                              : count === 0
                                ? "No Parcels Found"
                                : ""}
                    </h5>
                </div>
                <div
                    id="filter-control-actions"
                    className="d-flex flex-wrap justify-content-evenly"
                >
                    <button
                        className="btn btn-sm bg-gradient-secondary w-45"
                        onClick={clearFilters}
                    >
                        Clear Filters
                    </button>
                    <button
                        className="btn btn-sm bg-gradient-dark w-45"
                        onClick={() => applyFilters(dumpSearchFilters())}
                    >
                        Apply Filters
                    </button>
                    <button
                        className="btn btn-sm bg-gradient-info w-45"
                        onClick={() => setShowSaveListModal(true)}
                    >
                        Save List
                    </button>
                    <button
                        className="btn btn-sm bg-gradient-primary w-45"
                        onClick={() => setShowExportListModal(true)}
                    >
                        Export List
                    </button>
                </div>
            </div>
        </div>
    );
}

interface SectionHeaderProps {
    section: string;
    first?: boolean;
    children: ReactNode;
}

function SectionHeader({ section, children, first }: SectionHeaderProps) {
    return (
        <>
            <h5 data-section={section} className={`mb-1 ${first ? "" : "mt-5"}`}>
                {children}
            </h5>
            <hr className="horizontal dark mt-0" />
        </>
    );
}

interface LabelProps {
    htmlFor: string;
    tooltip?: string;
    children: ReactNode;
}

function Label({ htmlFor, tooltip, children }: LabelProps) {
    return (
        <label htmlFor={htmlFor}>
            {children}{" "}
            {tooltip && (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>{tooltip}</Tooltip>}
                >
                    <i className="fas fa-circle-question text-secondary" />
                </OverlayTrigger>
            )}
        </label>
    );
}

interface CountyFIPSSearchProps {
    id: string;
    onChange: (selected: CountyOption) => void;
    inputRef: React.Ref<TypeaheadRef>;
}

function CountyFIPSSearch({ id, onChange, inputRef }: CountyFIPSSearchProps) {
    const [options, setOptions] = useState<CountyOption[]>();
    const [loading, setLoading] = useState(false);

    const searchCounty = async (query: string) => {
        setLoading(true);
        try {
            const result: FIPSLookupResult = await fetch(
                `/api/property/fips_lookup/?name=${query}`,
            );
            setOptions(result?.results);
        } catch (xhr) {
            console.log("Error fetching FIPS lookup", xhr);
        }
        setLoading(false);
    };

    const onSearchResultClicked = (selection: Option[]) => {
        const option = selection[0] as CountyOption;
        if (option) {
            onChange(option);
        }
    };

    // Pass id to inputProps for htmlFor to work
    const inputProps = { id };

    return (
        <div className="input-group">
            <span className="input-group-text">
                <i className="fas fa-search" />
            </span>
            <LIAsyncTypeahead
                ref={inputRef}
                placeholder="Enter County Name"
                isLoading={loading}
                options={options}
                onSearch={searchCounty}
                onChange={onSearchResultClicked}
                onClear={() => onChange(null)}
                inputProps={inputProps}
            />
        </div>
    );
}

interface AutocompleteProps {
    id: string;
    county: string;
    field: string;
    onChange: (selected: string) => void;
    inputRef: React.Ref<TypeaheadRef>;
    placeholder: string;
    minLength?: number;
}

function Autocomplete({
    id,
    county,
    field,
    onChange,
    inputRef,
    placeholder,
    minLength,
}: AutocompleteProps) {
    const [options, setOptions] = useState<string[]>();
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const onSearch = async (query: string) => {
        setLoading(true);
        setIsValid(true);
        try {
            const params = { county, field, query };
            const queryString = new URLSearchParams(params).toString();
            const result: AutocompleteResult = await fetch(
                `/api/property/autocomplete/?${queryString}`,
            );
            setOptions(result?.data);
        } catch (xhr) {
            setIsValid(false);
            console.log("Error fetching autocomplete lookup", xhr);
        }
        setLoading(false);
    };

    const onSearchResultClicked = (selection: Option[]) => {
        const option = selection[0] as string;
        if (option) {
            onChange(option);
        }
    };

    // Pass id to inputProps for htmlFor to work
    const inputProps = { id };

    return (
        <div className="input-group">
            <LIAsyncTypeahead
                ref={inputRef}
                isLoading={loading}
                options={options}
                onSearch={onSearch}
                onChange={onSearchResultClicked}
                onClear={() => onChange("")}
                inputProps={inputProps}
                isInvalid={!isValid}
                placeholder={placeholder}
                minLength={minLength}
            />
        </div>
    );
}

// Based on: https://github.com/django/django/blob/main/django/forms/fields.py#L850
function toBool(value: any): boolean {
    if ([true, "True", "true", "1"].includes(value)) {
        return true;
    } else if ([false, "False", "false", "0"].includes(value)) {
        return false;
    } else {
        return undefined;
    }
}

function boolToStr(value: boolean): string {
    if (value === true) {
        return "true";
    } else if (value === false) {
        return "false";
    }
    return "";
}

interface BooleanSelectProps {
    id: string;
    value: boolean;
    onChange: (value: boolean) => void;
}

function BooleanSelect({ id, value, onChange }: BooleanSelectProps) {
    return (
        <select
            id={id}
            className="form-control"
            value={boolToStr(value)}
            onChange={(e) => onChange(toBool(e.target.value))}
        >
            <option value=""></option>
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
    );
}

interface EnumSelectProps {
    id: string;
    value: string;
    onChange: (value: string) => void;
    options: { value: string; label: string }[];
}

function EnumSelect({ id, value, onChange, options }: EnumSelectProps) {
    return (
        <select
            id={id}
            className="form-control"
            value={value}
            onChange={(e) => onChange(e.target.value)}
        >
            {options.map(({ value, label }) => (
                <option key={value} value={value}>
                    {label}
                </option>
            ))}
        </select>
    );
}

function generateExportListFilename(countyOption: CountyOption) {
    if (!countyOption) {
        return "";
    }
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${countyOption.county}-${countyOption.state}_${year}-${month}-${day}.csv`;
}

function generateSaveListTitle(countyOption: CountyOption) {
    if (!countyOption) {
        return "";
    }
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${countyOption.county}, ${countyOption.state} - ${year}.${month}.${day}`;
}
