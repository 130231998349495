import clsx from "clsx";
import { Typography } from "../typography/typography";
import { forwardRef } from "react";

import "./Toggle.css";

interface ToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
    checked?: boolean;
    label?: string;
    disabled?: boolean;
}

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
    ({ label, disabled, checked, ...props }: ToggleProps, ref) => {
        return (
            <label
                className={clsx(
                    "lui-inline-flex lui-items-center lui-gap-2 lui-cursor-pointer lui-m-0 lui-p-0",
                    {
                        "lui-cursor-not-allowed": disabled,
                    },
                )}
            >
                <span
                    className={clsx(`lui-toggle`, {
                        "lui-toggle-box-on": checked,
                        "lui-bg-gray-100": disabled,
                        "lui-bg-gray-500": !disabled && !checked,
                        "lui-bg-primary-500": !disabled && checked,
                    })}
                >
                    <input ref={ref} type="checkbox" checked={checked} {...props} />

                    <span
                        className={clsx("lui-toggle-button", {
                            "lui-toggle-button-on": checked,
                        })}
                    />
                </span>
                {label && (
                    <Typography variant="span" size="lg" weight="bold">
                        {label}
                    </Typography>
                )}
            </label>
        );
    },
);
