import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    useInteractions,
    FloatingPortal,
    arrow,
    FloatingArrow,
    hide,
    Side,
    useMergeRefs,
} from "@floating-ui/react";
import { useState, useRef, cloneElement, isValidElement, forwardRef } from "react";
import { Typography } from "../typography/typography";
import clsx from "clsx";

const ARROW_WIDTH = 10;
const ARROW_HEIGHT = 7;
const GAP = 10;

interface TooltipProps {
    content: React.ReactNode;
    trigger: React.ReactNode;
    className?: string;
    placement?: Side;
    showArrow?: boolean;
}
export const Tooltip = forwardRef<HTMLButtonElement, TooltipProps>(
    (
        {
            className,
            content,
            trigger,
            placement = "top",
            showArrow = false,
        }: TooltipProps,
        ref,
    ) => {
        const [isOpen, setIsOpen] = useState(false);

        const arrowRef = useRef(null);

        const { refs, floatingStyles, context } = useFloating({
            open: isOpen,
            onOpenChange: setIsOpen,
            placement: placement,
            whileElementsMounted: autoUpdate,
            middleware: [
                offset(showArrow ? ARROW_HEIGHT + GAP : GAP),
                flip(),
                shift(),
                arrow({
                    element: arrowRef,
                }),
                hide({
                    padding: 1,
                    altBoundary: true,
                    strategy: "referenceHidden", // 'referenceHidden' by default
                }),
            ],
        });

        const refMerged = useMergeRefs([refs.setReference, ref]);

        // Event listeners to change the open state
        const hover = useHover(context, { move: false });
        const focus = useFocus(context);
        const dismiss = useDismiss(context);
        // Role props for screen readers
        const role = useRole(context, { role: "tooltip" });

        // Merge all the interactions into prop getters
        const { getReferenceProps, getFloatingProps } = useInteractions([
            hover,
            focus,
            dismiss,
            role,
        ]);
        const triggerElement = isValidElement(trigger) ? (
            cloneElement(
                trigger,
                getReferenceProps({
                    ref: refMerged,
                    ...trigger.props,
                }),
            )
        ) : (
            <button
                ref={refMerged}
                className="lui-padding-0 lui-border-0 lui-bg-transparent"
                {...getReferenceProps()}
            >
                {trigger}
            </button>
        );

        return (
            <>
                {triggerElement}

                <FloatingPortal>
                    {isOpen && (
                        <div
                            className={clsx(
                                "lui-bg-gray-1000 lui-px-4 lui-py-2 lui-rounded-xl",
                                className,
                            )}
                            ref={refs.setFloating}
                            style={floatingStyles}
                            {...getFloatingProps()}
                        >
                            {showArrow && (
                                <FloatingArrow
                                    ref={arrowRef}
                                    context={context}
                                    className="lui-fill-gray-1000"
                                    width={ARROW_WIDTH}
                                    height={ARROW_HEIGHT}
                                />
                            )}
                            <Typography
                                color="white"
                                weight="medium"
                                size="sm"
                                className={className}
                            >
                                {content}
                            </Typography>
                        </div>
                    )}
                </FloatingPortal>
            </>
        );
    },
);
