export const RulerIcon = () => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.5 5.50001L16 7.00001M11.5 8.50001L13 10M8.49996 11.5L9.99996 13M5.49996 14.5L6.99996 16M2.56561 17.5657L6.43424 21.4344C6.63225 21.6324 6.73125 21.7314 6.84542 21.7685C6.94584 21.8011 7.05401 21.8011 7.15443 21.7685C7.2686 21.7314 7.3676 21.6324 7.56561 21.4344L21.4342 7.56573C21.6322 7.36772 21.7313 7.26872 21.7683 7.15455C21.801 7.05413 21.801 6.94596 21.7683 6.84554C21.7313 6.73137 21.6322 6.63237 21.4342 6.43436L17.5656 2.56573C17.3676 2.36772 17.2686 2.26872 17.1544 2.23163C17.054 2.199 16.9458 2.199 16.8454 2.23163C16.7313 2.26872 16.6322 2.36772 16.4342 2.56573L2.56561 16.4344C2.3676 16.6324 2.2686 16.7314 2.2315 16.8455C2.19887 16.946 2.19887 17.0541 2.2315 17.1546C2.2686 17.2687 2.3676 17.3677 2.56561 17.5657Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
