import { useLocalStorage } from "usehooks-ts";
import { Button } from "../../land_ui/button/button";
import { Popover } from "../../land_ui/popover/popover";
import { Toggle } from "../../land_ui/toggle_button/Toggle";
import { Tooltip } from "../../land_ui/tooltip/tooltip";
import { Typography } from "../../land_ui/typography/typography";
import { Divider } from "../../land_ui/Divider/Divider";

export function useDataLayerControl() {
    const [dataLayer, setDataLayer] = useLocalStorage("dataLayerControl", {
        countyLayer: false,
        zipLayer: false,
        wetLands: false,
        buildingLayer: false,
        roadsLayer: false,
    });

    return {
        dataLayer,
        setDataLayer: (newDataLayer: Partial<typeof dataLayer>) => {
            setDataLayer({
                ...dataLayer,
                ...newDataLayer,
            });
        },
    };
}
export function DataLayerControl() {
    const { dataLayer, setDataLayer } = useDataLayerControl();

    let layerOptions = [
        {
            name: "County Layer",
            state: dataLayer.countyLayer,
            setState: () => {
                setDataLayer({
                    countyLayer: !dataLayer.countyLayer,
                });
            },
        },
        {
            name: "Zip Layer",
            state: dataLayer.zipLayer,
            setState: () => {
                setDataLayer({
                    zipLayer: !dataLayer.zipLayer,
                });
            },
        },
        {
            name: "Wetlands",
            state: dataLayer.wetLands,
            setState: () => {
                setDataLayer({
                    wetLands: !dataLayer.wetLands,
                });
            },
        },
        {
            name: "Building Layer",
            state: dataLayer.buildingLayer,
            setState: () => {
                setDataLayer({
                    buildingLayer: !dataLayer.buildingLayer,
                });
            },
        },
        {
            name: "Roads Layer",
            state: dataLayer.roadsLayer,
            setState: () => {
                setDataLayer({
                    roadsLayer: !dataLayer.roadsLayer,
                });
            },
        },
    ];

    return (
        <Popover
            trigger={
                <div>
                    <Tooltip
                        trigger={<Button variant="secondary" icon="LayerTwo" />}
                        content="Data Layers"
                        placement="left"
                    />
                </div>
            }
            placement="left"
        >
            <div className="lui-flex lui-flex-col lui-py-2 lui-bg-white lui-rounded-[24px] lui-shadow-lg lui-min-w-[260px] lui-overflow-hidden">
                <Typography weight="bold" className="lui-py-2 lui-px-4">
                    Data Layers
                </Typography>
                <Divider horizontal />
                <div className="lui-flex lui-flex-col">
                    {layerOptions.map((layer) => (
                        <div className="lui-flex lui-justify-between lui-items-center lui-px-4 lui-py-2 hover:lui-bg-gray-100 ">
                            <Typography size="md" weight="medium">
                                {layer.name}
                            </Typography>
                            <Toggle checked={layer.state} onClick={layer.setState} />
                        </div>
                    ))}
                </div>
            </div>
        </Popover>
    );
}
